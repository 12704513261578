import type { FetchOptions, ResponseType } from 'ofetch';
import { $fetch } from 'ofetch';

const apiClientPlugin = defineNuxtPlugin(() => {
  const apiUrl = useRuntimeConfig().public.apiUrl;
  const access_token = useCookie('access_token');
  // eslint-disable-next-line node/prefer-global/process
  const baseURL = apiUrl || process.env.NUXT_PUBLIC_API_URL;
  // eslint-disable-next-line node/prefer-global/process
  const analyticsUrl = useRuntimeConfig().public.analyticsApiUrl || process.env.NUXT_PUBLIC_ANALYTICS_API_URL;

  const apiClientOptions: FetchOptions<ResponseType> = {
    onRequest({ request, options }) {
      const { getReloadKey } = useApiCacheControllerStore();

      const reload_key = getReloadKey(request.toString());
      if (!reload_key) {
        return;
      }

      options.query = {
        ...options.query,
        reload_key,
      };
    },
    onResponse() { },
    onRequestError({ request, options, error }) {
      throw new Error(`${request}, ${options}, ${error}`);
    },
    onResponseError({ request, options, response }) {
      throw new Error(`${request}, ${options}, ${response}`);
    },
  };

  const apiClient = $fetch.create({
    baseURL,
    credentials: 'include',
    headers: {
      Cookie: `access_token=${access_token.value}`,
    },
    ...apiClientOptions,
  });

  const analyticsApiClient = $fetch.create({
    baseURL: analyticsUrl,
    ...apiClientOptions,
  });

  return {
    provide: {
      api: apiClient,
      analyticsApi: analyticsApiClient,
    },
  };
});

export default apiClientPlugin;
