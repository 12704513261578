export interface RouterOption {
  path: string;
  name: string;
  page: string;
  meta?: { [key: string]: any };
  children?: RouterOption[];
}

export const RouterList: RouterOption[] = [
  {
    name: 'home',
    path: '/',
    page: 'home',
    meta: {
      isHeaderSticky: true,
    },
  },
  { name: 'projects', path: '/projects', page: 'projects' },
  { name: 'properties', path: '/properties', page: 'buy' },
  { name: 'about', path: '/about', page: 'about' },
  { name: 'project', path: '/project/:slug', page: 'single-project' },
  { name: 'availability', path: '/availability/:id', page: 'single-availability' },
  { name: 'developer', path: '/developer/:id', page: 'developer' },
  { name: 'profile', path: '/profile', page: 'profile', meta: { noFeedback: true } },
  {
    name: 'interactive-map',
    path: '/interactive-map',
    page: 'interactive-map',
    meta: { pageLayout: 'map-overlay' },
    children: [
      { name: 'map-project', path: 'project/:slug', page: 'single-project' },
      { name: 'map-availability', path: 'availability/:id', page: 'single-availability' },
    ],
  },
  { name: 'privacy', path: '/privacy', page: 'privacy', meta: { isHeaderSticky: true } },
  { name: 'terms', path: '/terms', page: 'terms', meta: { isHeaderSticky: true } },
  { name: 'user-data', path: '/user-data', page: 'user-data', meta: { isHeaderSticky: true } },
  { name: 'brochures', path: '/brochures', page: 'brochures', meta: { noFeedback: true } },
];

export const RouterListMobile: RouterOption[] = [
  {
    name: 'home',
    path: '/',
    page: 'home',
    meta: {
      isHeaderSticky: true,
      chat: true,
    },
  },
  { name: 'projects', path: '/projects', page: 'projects', meta: { chat: true, isHeaderSticky: true } },
  { name: 'properties', path: '/properties', page: 'buy', meta: { chat: true, noFeedback: true } },
  { name: 'properties-project', path: '/properties/:slug', page: 'buy', meta: { chat: true, noFeedback: true } },
  { name: 'project', path: '/project/:slug', page: 'single-project', meta: { isHeaderSticky: true } },
  { name: 'developer', path: '/developer/:id', page: 'developer', meta: { isHeaderSticky: true } },
  { name: 'interactive-map', path: '/interactive-map', page: 'interactive-map' },
  { name: 'availability', path: '/availability/:id', page: 'single-availability', meta: { isHeaderSticky: true } },
  { name: 'similar-properties', path: '/similar-properties/:id', page: 'similar-properties' },
  { name: 'profile', path: '/profile', page: 'profile', meta: { noFeedback: true } },
  {
    name: 'interactive-map',
    path: '/interactive-map',
    page: 'interactive-map',
    meta: { pageLayout: 'mobile-map-overlay' },
    children: [
      { name: 'map-project', path: 'project/:slug', page: 'single-project' },
      { name: 'map-availability', path: 'availability/:id', page: 'single-availability' },
    ],
  },
  { name: 'privacy', path: '/privacy', page: 'privacy', meta: { isHeaderSticky: true } },
  { name: 'terms', path: '/terms', page: 'terms', meta: { isHeaderSticky: true } },
  { name: 'user-data', path: '/user-data', page: 'user-data', meta: { isHeaderSticky: true } },
  { name: 'brochures', path: '/brochures', page: 'brochures', meta: { noFeedback: true } },
];
