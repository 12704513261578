export enum FiltersMaps {
  priceFrom = 'prf',
  priceTo = 'prt',
  squareFrom = 'szf',
  squareTo = 'szt',
  square = 'smt',
  currency = 'cur',
  handoverDates = 'hnd',
  unitTypes = 'uts',
  bedrooms = 'beds',
  bathrooms = 'baths',
  amenities = 'ams',
  projectIds = 'projectIds',
}

export interface FiltersStateArrays {
  handoverDates: string[];
  unitTypes: string[];
  bedrooms: string[];
  bathrooms: string[];
  amenities: string[];
  projectIds: string[];
}

export interface FiltersState extends FiltersStateArrays {
  priceFrom: string;
  priceTo: string;
  squareFrom: string;
  squareTo: string;
}

export type KeyOfFilterState = Exclude<keyof FiltersState, keyof FiltersStateArrays>;
