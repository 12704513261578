import { isMobileDevice } from '~/shared/utils/device-detect';

export default defineNuxtRouteMiddleware(async (to) => {
  if (to.name !== 'profile' && to.name !== 'brochures') {
    return true;
  }

  const { isAuth, fetchProfile, showAuthModal } = useAuth();
  await fetchProfile();

  if (!isAuth.value) {
    const isMobile = isMobileDevice();
    showAuthModal(isMobile);

    return navigateTo('/');
  }
});
