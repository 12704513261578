import { useSortingStore } from '@/stores/sort';
import { FiltersMaps } from '@/stores/filters/types';
import type { FiltersState, KeyOfFilterState } from '@/stores/filters/types';
import type { Sorting } from '~/config/sorting';
import type { LocationQueryRaw } from '#vue-router';
import { defaultSquare } from '~/config/square';
import { defaultCurrency } from '~/config/currency';

export const useFilterSortStore = defineStore('filterSort', () => {
  const router = useRouter();
  const route = useRoute();

  const configStore = useConfigStore();
  const { setSquare, setCurrency } = configStore;
  const { currency, square } = storeToRefs(configStore);

  const filtersStore = useFiltersStore();
  const { fetchFilters, getCurrentFilterParams, prepareHandoverDates, resetFilters } = filtersStore;
  const { filters } = storeToRefs(filtersStore);

  const sortingStore = useSortingStore();
  const { initSorting, updateSortingByName, resetSorting } = sortingStore;
  const { sorting } = storeToRefs(sortingStore);

  const filtersQueryParams = ref<LocationQueryRaw>({});

  async function init() {
    applyRouteToFilters();
    await fetchFilters();
  }

  init();

  function applyRouteToFilters(): void {
    const queryParams = filterQuery(route.query);

    if (route.query.orderBy) {
      queryParams.orderBy = updateSortingByName(route.query.orderBy.toString()).id;
    }
    if (route.query.smt) {
      setSquare(route.query.smt.toString(), false);
    }
    if (route.query.cur) {
      setCurrency(route.query.cur.toString(), false);
    }

    filtersQueryParams.value = prepareHandoverDates(queryParams);

    Object.entries(FiltersMaps).forEach(([key, value]) => {
      if (queryParams[value]) {
        const filterKey = key as keyof Omit<FiltersState, 'projects'>;
        const result = queryParams[value]?.toString() || '';

        const isNotArrayFiltersKey = isNotArrayField(filters.value, filterKey);
        if (isNotArrayFiltersKey) {
          filters.value[filterKey] = result;
        } else {
          filters.value[filterKey] = result.split(',');
        }
      }
    });
  }

  function isNotArrayField(filters: FiltersState, field: keyof Omit<FiltersState, 'projects'>): field is KeyOfFilterState {
    return !Array.isArray(filters[field]);
  }

  function isDefaultOrder(): Sorting | undefined {
    return sorting.value.id !== initSorting.id ? sorting.value : undefined;
  }

  function filterQuery(query: LocationQueryRaw) {
    const keysSet = new Set<string>(Object.values(FiltersMaps));
    const filteredObj: LocationQueryRaw = {};
    for (const key in query) {
      if (keysSet.has(key)) {
        filteredObj[key] = query[key];
      }
    }

    return filteredObj;
  }

  function resetFiltersSorting(): void {
    resetSorting();
    resetFilters();
  }

  watch(() => route.name, () => {
    resetFiltersSorting();
  });

  watch([filters.value, sorting, currency, square], async () => {
    const filters = getCurrentFilterParams({ ...route.query });
    const orderBy = isDefaultOrder()?.value;

    const smt = square.value !== defaultSquare.toString() ? square.value : undefined;
    const cur = currency.value !== defaultCurrency.toString() ? currency.value : undefined;

    const query = {
      ...filters,
      smt,
      cur,
      orderBy,
    };

    updateFiltersQuery(query);

    await router.replace({
      query,
      hash: route.hash,
    });
  });

  function updateFiltersQuery(query: Record<string, string | undefined>) {
    const queryJSON = JSON.stringify(query);
    const filtersJSON = JSON.stringify(filtersQueryParams.value);

    if (queryJSON !== filtersJSON) {
      const orderBy = isDefaultOrder()?.id;
      const queryParams = filterQuery(query);

      filtersQueryParams.value = {
        ...prepareHandoverDates(queryParams),
        orderBy,
      };
    }
  }

  return {
    filtersQueryParams,
  };
});
