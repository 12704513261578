import { default as brochures_46page_46mobileoEua0efmHnMeta } from "/app/src/pages/brochures/brochures.page.mobile.vue?macro=true";
import { default as brochures_46page3Ubj8PC1MuMeta } from "/app/src/pages/brochures/brochures.page.vue?macro=true";
import { default as BrochuresListSkeleton4RFxApGdgxMeta } from "/app/src/pages/brochures/components/brochures-list-skeleton/BrochuresListSkeleton.vue?macro=true";
import { default as BrochuresListWd6OrJ5j2sMeta } from "/app/src/pages/brochures/components/brochures-list/BrochuresList.vue?macro=true";
import { default as BrochureCardEz9bWfdcvhMeta } from "/app/src/pages/brochures/components/brochures-list/components/brochure-card/BrochureCard.vue?macro=true";
import { default as use_45brochures_45list_45modePPjkbzO2n2Meta } from "/app/src/pages/brochures/composables/use-brochures-list-mode.ts?macro=true";
import { default as use_45brochures_45listJWBszaVKf8Meta } from "/app/src/pages/brochures/composables/use-brochures-list.ts?macro=true";
import { default as buy_46page_46mobileAaxNirjns2Meta } from "/app/src/pages/buy/buy.page.mobile.vue?macro=true";
import { default as buy_46pagepi1qkZN4rWMeta } from "/app/src/pages/buy/buy.page.vue?macro=true";
import { default as BuyBreadcrumbsbrJFVi9li5Meta } from "/app/src/pages/buy/components/buy-breadcrumbs/BuyBreadcrumbs.vue?macro=true";
import { default as BuyPageFiltersJn0BcAAUz3Meta } from "/app/src/pages/buy/components/filters/BuyPageFilters.vue?macro=true";
import { default as BuyPageFiltersProjectSelectSnlJsL2tCPMeta } from "/app/src/pages/buy/components/filters/components/projects-select/BuyPageFiltersProjectSelect.vue?macro=true";
import { default as MobileAvailabilityListu5sjOSov1TMeta } from "/app/src/pages/buy/components/mobile-availability-list/MobileAvailabilityList.vue?macro=true";
import { default as MobileAvailabilitySkeletonxhNBW20R5uMeta } from "/app/src/pages/buy/components/mobile-availability-skeleton/MobileAvailabilitySkeleton.vue?macro=true";
import { default as MobileFloorplanListfLlrID6cK0Meta } from "/app/src/pages/buy/components/mobile-floorplan-list/MobileFloorplanList.vue?macro=true";
import { default as AboutDeveloperSection83esCqKYZ9Meta } from "/app/src/pages/developer/components/about-developer-section/AboutDeveloperSection.vue?macro=true";
import { default as DeveloperAwardsuf0VFovaJnMeta } from "/app/src/pages/developer/components/developer-awards/DeveloperAwards.vue?macro=true";
import { default as DeveloperFlagshipProjectsSectionFW4GHE49ybMeta } from "/app/src/pages/developer/components/developer-flagship-projects-section/DeveloperFlagshipProjectsSection.vue?macro=true";
import { default as DeveloperProjectsSectionwFR7kwroEQMeta } from "/app/src/pages/developer/components/developer-projects-section/DeveloperProjectsSection.vue?macro=true";
import { default as DeveloperMapSection_46client7NAJZ9maOSMeta } from "/app/src/pages/developer/components/map-section/components/section/DeveloperMapSection.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/app/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as DeveloperMapSectionWrapper_46clientmk80AYRVe5Meta } from "/app/src/pages/developer/components/map-section/DeveloperMapSectionWrapper.client.vue?macro=true";
import { default as developer_46page_46mobileJhwPyLVX7TMeta } from "/app/src/pages/developer/developer.page.mobile.vue?macro=true";
import { default as developer_46pagezvCtYQEb4yMeta } from "/app/src/pages/developer/developer.page.vue?macro=true";
import { default as AvailabilitiesSectionbU0gFm6NmoMeta } from "/app/src/pages/home/components/availabilities-section/AvailabilitiesSection.vue?macro=true";
import { default as BrochuresSectionMj4AygvYaFMeta } from "/app/src/pages/home/components/brochures-section/BrochuresSection.vue?macro=true";
import { default as BrowseMapSection5AtjrCvDgLMeta } from "/app/src/pages/home/components/browse-map-section/BrowseMapSection.vue?macro=true";
import { default as MobileAllProjectsSectionQqdCI8Wr9VMeta } from "/app/src/pages/home/components/mobile-all-projects-section/MobileAllProjectsSection.vue?macro=true";
import { default as MobileExclusiveSection0Qeqd5ADIyMeta } from "/app/src/pages/home/components/mobile-exclusive-section/MobileExclusiveSection.vue?macro=true";
import { default as MobileMainSectionBEEILXRKLOMeta } from "/app/src/pages/home/components/mobile-main-section/MobileMainSection.vue?macro=true";
import { default as MobilePropertiesSectionbMBa0mjCBYMeta } from "/app/src/pages/home/components/mobile-properties-section/MobilePropertiesSection.vue?macro=true";
import { default as home_46page_46mobileK3G2YgDONjMeta } from "/app/src/pages/home/home.page.mobile.vue?macro=true";
import { default as home_46pagemrxXngca0DMeta } from "/app/src/pages/home/home.page.vue?macro=true";
import { default as index9zIfZeyvbeMeta } from "/app/src/pages/interactive-map/components/controls/composables/use-map-controls/index.ts?macro=true";
import { default as MapControls4tvt5w3VKcMeta } from "/app/src/pages/interactive-map/components/controls/desktop/MapControls.vue?macro=true";
import { default as MobileMapControlsXdQ4dB6zAJMeta } from "/app/src/pages/interactive-map/components/controls/mobile/MobileMapControls.vue?macro=true";
import { default as MapFavoriteProjectCardBijsMu0AMnMeta } from "/app/src/pages/interactive-map/components/favorites/card/MapFavoriteProjectCard.vue?macro=true";
import { default as MapFavoritesControllerButtonyDDKLEqIBqMeta } from "/app/src/pages/interactive-map/components/favorites/controller/MapFavoritesControllerButton.vue?macro=true";
import { default as constantsJ5Eg3gLT2YMeta } from "/app/src/pages/interactive-map/components/favorites/dots-provider/constants.ts?macro=true";
import { default as MapFavoritesDotsProvidercTtbyUozrZMeta } from "/app/src/pages/interactive-map/components/favorites/dots-provider/MapFavoritesDotsProvider.vue?macro=true";
import { default as MapFavoritesWindowWizVm37WlCMeta } from "/app/src/pages/interactive-map/components/favorites/window/MapFavoritesWindow.vue?macro=true";
import { default as MapFilterAmenities5ZFGtkaDeyMeta } from "/app/src/pages/interactive-map/components/filters/amenities/MapFilterAmenities.vue?macro=true";
import { default as MapProjectSearchOption3jYXBJzu8OMeta } from "/app/src/pages/interactive-map/components/filters/search-items/components/project-search-option/MapProjectSearchOption.vue?macro=true";
import { default as MapFiltersSearchItemsWD4fySamhkMeta } from "/app/src/pages/interactive-map/components/filters/search-items/MapFiltersSearchItems.vue?macro=true";
import { default as MapWindowFilterTemplate2YL4jLkeWcMeta } from "/app/src/pages/interactive-map/components/filters/template/MapWindowFilterTemplate.vue?macro=true";
import { default as MapFiltersWindowWPD5x4lQKiMeta } from "/app/src/pages/interactive-map/components/filters/window/MapFiltersWindow.vue?macro=true";
import { default as constants4HDKsQhNWbMeta } from "/app/src/pages/interactive-map/components/help/components/onboarding/constants.ts?macro=true";
import { default as MapOnboardinggsmfqqOGSJMeta } from "/app/src/pages/interactive-map/components/help/components/onboarding/MapOnboarding.vue?macro=true";
import { default as MapUserHelpItemsLQmsEpjlBQMeta } from "/app/src/pages/interactive-map/components/help/MapUserHelpItems.vue?macro=true";
import { default as MobileMapActionFiltersd08B26dFyKMeta } from "/app/src/pages/interactive-map/components/mobile-actions/mobile-map-action-filters/MobileMapActionFilters.vue?macro=true";
import { default as MobileMapActionMenuQfOzzPuAEJMeta } from "/app/src/pages/interactive-map/components/mobile-actions/mobile-map-action-menu/MobileMapActionMenu.vue?macro=true";
import { default as MobileMapActionSearchN84ALaBTlhMeta } from "/app/src/pages/interactive-map/components/mobile-actions/mobile-map-action-search/MobileMapActionSearch.vue?macro=true";
import { default as MobileMapActions0ZU30khSWTMeta } from "/app/src/pages/interactive-map/components/mobile-actions/MobileMapActions.vue?macro=true";
import { default as MobileMapBurgerMenuAZQHc3N1UnMeta } from "/app/src/pages/interactive-map/components/mobile-burger-menu/MobileMapBurgerMenu.vue?macro=true";
import { default as MobileFavoritesModalnRARhdMrKnMeta } from "/app/src/pages/interactive-map/components/mobile-favorites-modal/MobileFavoritesModal.vue?macro=true";
import { default as MobileMapProjectTabsv4UU5SgxzKMeta } from "/app/src/pages/interactive-map/components/mobile-map-project-tabs/MobileMapProjectTabs.vue?macro=true";
import { default as MobileMapOnboarding0LU9Q8IMmhMeta } from "/app/src/pages/interactive-map/components/mobile-onboarding/MobileMapOnboarding.vue?macro=true";
import { default as MobileMapSearchOverlay4W3NLLoHyTMeta } from "/app/src/pages/interactive-map/components/mobile-search-overlay/MobileMapSearchOverlay.vue?macro=true";
import { default as MapModalRouterViewdGvfFvoYuPMeta } from "/app/src/pages/interactive-map/components/overlay-router-view/MapModalRouterView.vue?macro=true";
import { default as BackToProjectZI7A1zU3TsMeta } from "/app/src/pages/interactive-map/components/project/project-hud/components/back-to-project/BackToProject.vue?macro=true";
import { default as ProjectAmenitiesRG8QVRPXcKMeta } from "/app/src/pages/interactive-map/components/project/project-hud/components/project-amenities/ProjectAmenities.vue?macro=true";
import { default as ProjectFacilitieswohPfodsdzMeta } from "/app/src/pages/interactive-map/components/project/project-hud/components/project-facilities/ProjectFacilities.vue?macro=true";
import { default as useAmenitiesUtilsVxD6ZytSBDMeta } from "/app/src/pages/interactive-map/components/project/project-hud/composables/useAmenitiesUtils.ts?macro=true";
import { default as useFacilitiesUtilsmj7gA8Kj3AMeta } from "/app/src/pages/interactive-map/components/project/project-hud/composables/useFacilitiesUtils.ts?macro=true";
import { default as useProjectTabsmBndRavgoRMeta } from "/app/src/pages/interactive-map/components/project/project-hud/composables/useProjectTabs.ts?macro=true";
import { default as MapProjectHud0hVgx5WbwWMeta } from "/app/src/pages/interactive-map/components/project/project-hud/MapProjectHud.vue?macro=true";
import { default as MapLoginfAgfR5bKVDMeta } from "/app/src/pages/interactive-map/components/top-right-items/components/map-login/MapLogin.vue?macro=true";
import { default as MapSettingsPicker00SJrx0JfDMeta } from "/app/src/pages/interactive-map/components/top-right-items/components/settings-picker/MapSettingsPicker.vue?macro=true";
import { default as MapTopRightItemsd5sqBGQ3i2Meta } from "/app/src/pages/interactive-map/components/top-right-items/MapTopRightItems.vue?macro=true";
import { default as index4pvqyIPrPdMeta } from "/app/src/pages/interactive-map/composables/use-block-zoom/index.ts?macro=true";
import { default as indexleoCVRLBsDMeta } from "/app/src/pages/interactive-map/composables/use-mobile-map-onboarding/index.ts?macro=true";
import { default as map_45config3aWAkjF5fdMeta } from "/app/src/pages/interactive-map/constants/map-config.ts?macro=true";
import { default as seoUXZvLnY8eYMeta } from "/app/src/pages/interactive-map/constants/seo.ts?macro=true";
import { default as interactive_45map_46page_46mobileVTBNxDcx1WMeta } from "/app/src/pages/interactive-map/interactive-map.page.mobile.vue?macro=true";
import { default as interactive_45map_46pagegKSrbeYhT5Meta } from "/app/src/pages/interactive-map/interactive-map.page.vue?macro=true";
import { default as PrivacyContentydu5nWHIRqMeta } from "/app/src/pages/privacy/components/PrivacyContent.vue?macro=true";
import { default as seoAfPYCS7le2Meta } from "/app/src/pages/privacy/constants/seo.ts?macro=true";
import { default as privacy_46page_46mobile8PJvpIPos5Meta } from "/app/src/pages/privacy/privacy.page.mobile.vue?macro=true";
import { default as privacy_46pageduaGlnI8IuMeta } from "/app/src/pages/privacy/privacy.page.vue?macro=true";
import { default as AvatarSectionsALG9qbfgbMeta } from "/app/src/pages/profile/components/avatar-section/desktop/AvatarSection.vue?macro=true";
import { default as MobileAvatarSectionibCt1fF4YbMeta } from "/app/src/pages/profile/components/avatar-section/mobile/MobileAvatarSection.vue?macro=true";
import { default as indexECptGkyc9sMeta } from "/app/src/pages/profile/components/favorites-section/composables/use-favorites-list/index.ts?macro=true";
import { default as FavoritesSectionKUGdxazGjxMeta } from "/app/src/pages/profile/components/favorites-section/desktop/FavoritesSection.vue?macro=true";
import { default as FavoriteAvailabilityItemryRaASGLA9Meta } from "/app/src/pages/profile/components/favorites-section/item/availability/FavoriteAvailabilityItem.vue?macro=true";
import { default as FavoriteProjectItempWTzgb36JOMeta } from "/app/src/pages/profile/components/favorites-section/item/project/FavoriteProjectItem.vue?macro=true";
import { default as MobileFavoritesSectionSHAnTkrOWvMeta } from "/app/src/pages/profile/components/favorites-section/mobile/MobileFavoritesSection.vue?macro=true";
import { default as seogvH8yjX91RMeta } from "/app/src/pages/profile/constants/seo.ts?macro=true";
import { default as profile_46page_46mobileHNSleJdIppMeta } from "/app/src/pages/profile/profile.page.mobile.vue?macro=true";
import { default as profile_46pageAJJNNWBDtxMeta } from "/app/src/pages/profile/profile.page.vue?macro=true";
import { default as MobileExclusiveProjectsListzx7uIO2sqSMeta } from "/app/src/pages/projects/components/mobile-exclusive-projects-list/MobileExclusiveProjectsList.vue?macro=true";
import { default as MobileProjectSkeletonVywhXS5jU7Meta } from "/app/src/pages/projects/components/mobile-project-skeleton/MobileProjectSkeleton.vue?macro=true";
import { default as MobileProjectsListoRYsmQvghgMeta } from "/app/src/pages/projects/components/mobile-projects-list/MobileProjectsList.vue?macro=true";
import { default as MobileProjectsSections7dBNEdK8aMeta } from "/app/src/pages/projects/components/mobile-projects-section/MobileProjectsSection.vue?macro=true";
import { default as projects_46page_46mobiley1r7TAGU92Meta } from "/app/src/pages/projects/projects.page.mobile.vue?macro=true";
import { default as projects_46page9VpRgwwLpiMeta } from "/app/src/pages/projects/projects.page.vue?macro=true";
import { default as similar_45properties_46page_46mobilejWPankq7AQMeta } from "/app/src/pages/similar-properties/similar-properties.page.mobile.vue?macro=true";
import { default as AvailabilityNavbarezE6m7ivu6Meta } from "/app/src/pages/single-availability/components/availability-navbar/AvailabilityNavbar.vue?macro=true";
import { default as AvailabilityPlug4GQSnzR6HHMeta } from "/app/src/pages/single-availability/components/availability-plug/AvailabilityPlug.vue?macro=true";
import { default as GallerySection0Pv5bnf1txMeta } from "/app/src/pages/single-availability/components/gallery-section/GallerySection.vue?macro=true";
import { default as Availability3DFloorPlan_46clienteK7hjpgKAPMeta } from "/app/src/pages/single-availability/components/main-section/components/availability-3d-floorplan/Availability3DFloorPlan.client.vue?macro=true";
import { default as AvailabilityDetails1R25suVfRtMeta } from "/app/src/pages/single-availability/components/main-section/components/availabilty-details/AvailabilityDetails.vue?macro=true";
import { default as MainSectiondV9v7nB2mLMeta } from "/app/src/pages/single-availability/components/main-section/MainSection.vue?macro=true";
import { default as MobileFloorplanDetails2IQ3XFwymWMeta } from "/app/src/pages/single-availability/components/mobile/details-section/components/mobile-floorplan-details/MobileFloorplanDetails.vue?macro=true";
import { default as MobileAvailabilityDetailsSection3TIxnAPVrdMeta } from "/app/src/pages/single-availability/components/mobile/details-section/MobileAvailabilityDetailsSection.vue?macro=true";
import { default as MobileAvailabilityFloorplanOverlayEv5PkRwbTDMeta } from "/app/src/pages/single-availability/components/mobile/main-section/components/mobile-availability-floorplan-overlay/MobileAvailabilityFloorplanOverlay.vue?macro=true";
import { default as MobileAvailabilityMainSectionhkmXt8UxmqMeta } from "/app/src/pages/single-availability/components/mobile/main-section/MobileAvailabilityMainSection.vue?macro=true";
import { default as MobileSimilarPropertiesSectioncBjvoiy7mhMeta } from "/app/src/pages/single-availability/components/mobile/similar-properties-section/MobileSimilarPropertiesSection.vue?macro=true";
import { default as RegulatorySection5M90Klw57GMeta } from "/app/src/pages/single-availability/components/regulatory-section/RegulatorySection.vue?macro=true";
import { default as SimilarPropertiesSectiondaKHLTR1ZjMeta } from "/app/src/pages/single-availability/components/similar-properties-section/SimilarPropertiesSection.vue?macro=true";
import { default as SingleAvailabilitySkeletonMobiledxpEOlUwXzMeta } from "/app/src/pages/single-availability/components/single-availability-skeleton-mobile/SingleAvailabilitySkeletonMobile.vue?macro=true";
import { default as SingleAvailabilitySkeletonYbOOp2XQJ8Meta } from "/app/src/pages/single-availability/components/single-availability-skeleton/SingleAvailabilitySkeleton.vue?macro=true";
import { default as use_45head_45metaKRHKpgx5sfMeta } from "/app/src/pages/single-availability/composables/use-head-meta.ts?macro=true";
import { default as use_45single_45availabilityBtsaYXo3UfMeta } from "/app/src/pages/single-availability/composables/use-single-availability.ts?macro=true";
import { default as single_45availability_46page_46mobile8aCr7i0vJ0Meta } from "/app/src/pages/single-availability/single-availability.page.mobile.vue?macro=true";
import { default as single_45availability_46pageAms9m1POw8Meta } from "/app/src/pages/single-availability/single-availability.page.vue?macro=true";
import { default as AvailabilitiesSectionZBhCYOCgDoMeta } from "/app/src/pages/single-project/components/availabilities-section/AvailabilitiesSection.vue?macro=true";
import { default as ProjectAvailabilitiesSectionFiltersdgJb5BzM3kMeta } from "/app/src/pages/single-project/components/availabilities-section/components/filters/ProjectAvailabilitiesSectionFilters.vue?macro=true";
import { default as DownloadBrochureButtonDqHl62caC8Meta } from "/app/src/pages/single-project/components/download-brochure-button/DownloadBrochureButton.vue?macro=true";
import { default as MainSectionBpbpjovcU4Meta } from "/app/src/pages/single-project/components/main-section/MainSection.vue?macro=true";
import { default as MobileAvailabilityListBmYg3J8aLRMeta } from "/app/src/pages/single-project/components/mobile/availabilities-section/components/mobile-availability-list/MobileAvailabilityList.vue?macro=true";
import { default as MobileFloorplanList0fUH5AtVocMeta } from "/app/src/pages/single-project/components/mobile/availabilities-section/components/mobile-floorplan-list/MobileFloorplanList.vue?macro=true";
import { default as MobileAvailabilitiesSectionR8aJTJtgE6Meta } from "/app/src/pages/single-project/components/mobile/availabilities-section/MobileAvailabilitiesSection.vue?macro=true";
import { default as MobileProjectMainSectionHqw0ISoW7SMeta } from "/app/src/pages/single-project/components/mobile/project-main-section/MobileProjectMainSection.vue?macro=true";
import { default as ProjectNavbarZZ8x9TYXZzMeta } from "/app/src/pages/single-project/components/project-navbar/ProjectNavbar.vue?macro=true";
import { default as SingleProjectSkeletonMobilej3hgkhVBPuMeta } from "/app/src/pages/single-project/components/single-project-skeleton-mobile/SingleProjectSkeletonMobile.vue?macro=true";
import { default as SingleProjectSkeletonfAZy3RcdDnMeta } from "/app/src/pages/single-project/components/single-project-skeleton/SingleProjectSkeleton.vue?macro=true";
import { default as indexrcOGP5BH0nMeta } from "/app/src/pages/single-project/composables/index.ts?macro=true";
import { default as use_45page_45headSrba4t8igdMeta } from "/app/src/pages/single-project/composables/use-page-head.ts?macro=true";
import { default as use_45single_45projectrYHSGCmaIMMeta } from "/app/src/pages/single-project/composables/use-single-project.ts?macro=true";
import { default as single_45project_46page_46mobilefHB6gccwdhMeta } from "/app/src/pages/single-project/single-project.page.mobile.vue?macro=true";
import { default as single_45project_46page6ew6PWRPPIMeta } from "/app/src/pages/single-project/single-project.page.vue?macro=true";
import { default as TermsContentN1UOkyDeH9Meta } from "/app/src/pages/terms/components/TermsContent.vue?macro=true";
import { default as seoH3EPurRuvOMeta } from "/app/src/pages/terms/constants/seo.ts?macro=true";
import { default as terms_46page_46mobileWXaKAIGqJEMeta } from "/app/src/pages/terms/terms.page.mobile.vue?macro=true";
import { default as terms_46pagep6DdxV7bKMMeta } from "/app/src/pages/terms/terms.page.vue?macro=true";
import { default as user_45data_45contentBjAWtmzdl3Meta } from "/app/src/pages/user-data/components/user-data-content.vue?macro=true";
import { default as seo1CiuqK5T84Meta } from "/app/src/pages/user-data/constants/seo.ts?macro=true";
import { default as user_45data_46page_46mobileFnzboWBrkBMeta } from "/app/src/pages/user-data/user-data.page.mobile.vue?macro=true";
import { default as user_45data_46pagetpStnDHkULMeta } from "/app/src/pages/user-data/user-data.page.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "brochures-brochures.page.mobile",
    path: "/brochures/brochures.page.mobile",
    component: () => import("/app/src/pages/brochures/brochures.page.mobile.vue")
  },
  {
    name: "brochures-brochures.page",
    path: "/brochures/brochures.page",
    component: () => import("/app/src/pages/brochures/brochures.page.vue")
  },
  {
    name: "brochures-components-brochures-list-skeleton-BrochuresListSkeleton",
    path: "/brochures/components/brochures-list-skeleton/BrochuresListSkeleton",
    component: () => import("/app/src/pages/brochures/components/brochures-list-skeleton/BrochuresListSkeleton.vue")
  },
  {
    name: "brochures-components-brochures-list-BrochuresList",
    path: "/brochures/components/brochures-list/BrochuresList",
    component: () => import("/app/src/pages/brochures/components/brochures-list/BrochuresList.vue")
  },
  {
    name: "brochures-components-brochures-list-components-brochure-card-BrochureCard",
    path: "/brochures/components/brochures-list/components/brochure-card/BrochureCard",
    component: () => import("/app/src/pages/brochures/components/brochures-list/components/brochure-card/BrochureCard.vue")
  },
  {
    name: "brochures-composables-use-brochures-list-mode",
    path: "/brochures/composables/use-brochures-list-mode",
    component: () => import("/app/src/pages/brochures/composables/use-brochures-list-mode.ts")
  },
  {
    name: "brochures-composables-use-brochures-list",
    path: "/brochures/composables/use-brochures-list",
    component: () => import("/app/src/pages/brochures/composables/use-brochures-list.ts")
  },
  {
    name: "buy-buy.page.mobile",
    path: "/buy/buy.page.mobile",
    component: () => import("/app/src/pages/buy/buy.page.mobile.vue")
  },
  {
    name: "buy-buy.page",
    path: "/buy/buy.page",
    component: () => import("/app/src/pages/buy/buy.page.vue")
  },
  {
    name: "buy-components-buy-breadcrumbs-BuyBreadcrumbs",
    path: "/buy/components/buy-breadcrumbs/BuyBreadcrumbs",
    component: () => import("/app/src/pages/buy/components/buy-breadcrumbs/BuyBreadcrumbs.vue")
  },
  {
    name: "buy-components-filters-BuyPageFilters",
    path: "/buy/components/filters/BuyPageFilters",
    component: () => import("/app/src/pages/buy/components/filters/BuyPageFilters.vue")
  },
  {
    name: "buy-components-filters-components-projects-select-BuyPageFiltersProjectSelect",
    path: "/buy/components/filters/components/projects-select/BuyPageFiltersProjectSelect",
    component: () => import("/app/src/pages/buy/components/filters/components/projects-select/BuyPageFiltersProjectSelect.vue")
  },
  {
    name: "buy-components-mobile-availability-list-MobileAvailabilityList",
    path: "/buy/components/mobile-availability-list/MobileAvailabilityList",
    component: () => import("/app/src/pages/buy/components/mobile-availability-list/MobileAvailabilityList.vue")
  },
  {
    name: "buy-components-mobile-availability-skeleton-MobileAvailabilitySkeleton",
    path: "/buy/components/mobile-availability-skeleton/MobileAvailabilitySkeleton",
    component: () => import("/app/src/pages/buy/components/mobile-availability-skeleton/MobileAvailabilitySkeleton.vue")
  },
  {
    name: "buy-components-mobile-floorplan-list-MobileFloorplanList",
    path: "/buy/components/mobile-floorplan-list/MobileFloorplanList",
    component: () => import("/app/src/pages/buy/components/mobile-floorplan-list/MobileFloorplanList.vue")
  },
  {
    name: "developer-components-about-developer-section-AboutDeveloperSection",
    path: "/developer/components/about-developer-section/AboutDeveloperSection",
    component: () => import("/app/src/pages/developer/components/about-developer-section/AboutDeveloperSection.vue")
  },
  {
    name: "developer-components-developer-awards-DeveloperAwards",
    path: "/developer/components/developer-awards/DeveloperAwards",
    component: () => import("/app/src/pages/developer/components/developer-awards/DeveloperAwards.vue")
  },
  {
    name: "developer-components-developer-flagship-projects-section-DeveloperFlagshipProjectsSection",
    path: "/developer/components/developer-flagship-projects-section/DeveloperFlagshipProjectsSection",
    component: () => import("/app/src/pages/developer/components/developer-flagship-projects-section/DeveloperFlagshipProjectsSection.vue")
  },
  {
    name: "developer-components-developer-projects-section-DeveloperProjectsSection",
    path: "/developer/components/developer-projects-section/DeveloperProjectsSection",
    component: () => import("/app/src/pages/developer/components/developer-projects-section/DeveloperProjectsSection.vue")
  },
  {
    name: "developer-components-map-section-components-section-DeveloperMapSection",
    path: "/developer/components/map-section/components/section/DeveloperMapSection",
    component: () => createClientPage(() => import("/app/src/pages/developer/components/map-section/components/section/DeveloperMapSection.client.vue"))
  },
  {
    name: "developer-components-map-section-DeveloperMapSectionWrapper",
    path: "/developer/components/map-section/DeveloperMapSectionWrapper",
    component: () => createClientPage(() => import("/app/src/pages/developer/components/map-section/DeveloperMapSectionWrapper.client.vue"))
  },
  {
    name: "developer-developer.page.mobile",
    path: "/developer/developer.page.mobile",
    component: () => import("/app/src/pages/developer/developer.page.mobile.vue")
  },
  {
    name: "developer-developer.page",
    path: "/developer/developer.page",
    component: () => import("/app/src/pages/developer/developer.page.vue")
  },
  {
    name: "home-components-availabilities-section-AvailabilitiesSection",
    path: "/home/components/availabilities-section/AvailabilitiesSection",
    component: () => import("/app/src/pages/home/components/availabilities-section/AvailabilitiesSection.vue")
  },
  {
    name: "home-components-brochures-section-BrochuresSection",
    path: "/home/components/brochures-section/BrochuresSection",
    component: () => import("/app/src/pages/home/components/brochures-section/BrochuresSection.vue")
  },
  {
    name: "home-components-browse-map-section-BrowseMapSection",
    path: "/home/components/browse-map-section/BrowseMapSection",
    component: () => import("/app/src/pages/home/components/browse-map-section/BrowseMapSection.vue")
  },
  {
    name: "home-components-mobile-all-projects-section-MobileAllProjectsSection",
    path: "/home/components/mobile-all-projects-section/MobileAllProjectsSection",
    component: () => import("/app/src/pages/home/components/mobile-all-projects-section/MobileAllProjectsSection.vue")
  },
  {
    name: "home-components-mobile-exclusive-section-MobileExclusiveSection",
    path: "/home/components/mobile-exclusive-section/MobileExclusiveSection",
    component: () => import("/app/src/pages/home/components/mobile-exclusive-section/MobileExclusiveSection.vue")
  },
  {
    name: "home-components-mobile-main-section-MobileMainSection",
    path: "/home/components/mobile-main-section/MobileMainSection",
    component: () => import("/app/src/pages/home/components/mobile-main-section/MobileMainSection.vue")
  },
  {
    name: "home-components-mobile-properties-section-MobilePropertiesSection",
    path: "/home/components/mobile-properties-section/MobilePropertiesSection",
    component: () => import("/app/src/pages/home/components/mobile-properties-section/MobilePropertiesSection.vue")
  },
  {
    name: "home-home.page.mobile",
    path: "/home/home.page.mobile",
    component: () => import("/app/src/pages/home/home.page.mobile.vue")
  },
  {
    name: "home-home.page",
    path: "/home/home.page",
    component: () => import("/app/src/pages/home/home.page.vue")
  },
  {
    name: "interactive-map-components-controls-composables-use-map-controls",
    path: "/interactive-map/components/controls/composables/use-map-controls",
    component: () => import("/app/src/pages/interactive-map/components/controls/composables/use-map-controls/index.ts")
  },
  {
    name: "interactive-map-components-controls-desktop-MapControls",
    path: "/interactive-map/components/controls/desktop/MapControls",
    component: () => import("/app/src/pages/interactive-map/components/controls/desktop/MapControls.vue")
  },
  {
    name: "interactive-map-components-controls-mobile-MobileMapControls",
    path: "/interactive-map/components/controls/mobile/MobileMapControls",
    component: () => import("/app/src/pages/interactive-map/components/controls/mobile/MobileMapControls.vue")
  },
  {
    name: "interactive-map-components-favorites-card-MapFavoriteProjectCard",
    path: "/interactive-map/components/favorites/card/MapFavoriteProjectCard",
    component: () => import("/app/src/pages/interactive-map/components/favorites/card/MapFavoriteProjectCard.vue")
  },
  {
    name: "interactive-map-components-favorites-controller-MapFavoritesControllerButton",
    path: "/interactive-map/components/favorites/controller/MapFavoritesControllerButton",
    component: () => import("/app/src/pages/interactive-map/components/favorites/controller/MapFavoritesControllerButton.vue")
  },
  {
    name: "interactive-map-components-favorites-dots-provider-constants",
    path: "/interactive-map/components/favorites/dots-provider/constants",
    component: () => import("/app/src/pages/interactive-map/components/favorites/dots-provider/constants.ts")
  },
  {
    name: "interactive-map-components-favorites-dots-provider-MapFavoritesDotsProvider",
    path: "/interactive-map/components/favorites/dots-provider/MapFavoritesDotsProvider",
    component: () => import("/app/src/pages/interactive-map/components/favorites/dots-provider/MapFavoritesDotsProvider.vue")
  },
  {
    name: "interactive-map-components-favorites-window-MapFavoritesWindow",
    path: "/interactive-map/components/favorites/window/MapFavoritesWindow",
    component: () => import("/app/src/pages/interactive-map/components/favorites/window/MapFavoritesWindow.vue")
  },
  {
    name: "interactive-map-components-filters-amenities-MapFilterAmenities",
    path: "/interactive-map/components/filters/amenities/MapFilterAmenities",
    component: () => import("/app/src/pages/interactive-map/components/filters/amenities/MapFilterAmenities.vue")
  },
  {
    name: "interactive-map-components-filters-search-items-components-project-search-option-MapProjectSearchOption",
    path: "/interactive-map/components/filters/search-items/components/project-search-option/MapProjectSearchOption",
    component: () => import("/app/src/pages/interactive-map/components/filters/search-items/components/project-search-option/MapProjectSearchOption.vue")
  },
  {
    name: "interactive-map-components-filters-search-items-MapFiltersSearchItems",
    path: "/interactive-map/components/filters/search-items/MapFiltersSearchItems",
    component: () => import("/app/src/pages/interactive-map/components/filters/search-items/MapFiltersSearchItems.vue")
  },
  {
    name: "interactive-map-components-filters-template-MapWindowFilterTemplate",
    path: "/interactive-map/components/filters/template/MapWindowFilterTemplate",
    component: () => import("/app/src/pages/interactive-map/components/filters/template/MapWindowFilterTemplate.vue")
  },
  {
    name: "interactive-map-components-filters-window-MapFiltersWindow",
    path: "/interactive-map/components/filters/window/MapFiltersWindow",
    component: () => import("/app/src/pages/interactive-map/components/filters/window/MapFiltersWindow.vue")
  },
  {
    name: "interactive-map-components-help-components-onboarding-constants",
    path: "/interactive-map/components/help/components/onboarding/constants",
    component: () => import("/app/src/pages/interactive-map/components/help/components/onboarding/constants.ts")
  },
  {
    name: "interactive-map-components-help-components-onboarding-MapOnboarding",
    path: "/interactive-map/components/help/components/onboarding/MapOnboarding",
    component: () => import("/app/src/pages/interactive-map/components/help/components/onboarding/MapOnboarding.vue")
  },
  {
    name: "interactive-map-components-help-MapUserHelpItems",
    path: "/interactive-map/components/help/MapUserHelpItems",
    component: () => import("/app/src/pages/interactive-map/components/help/MapUserHelpItems.vue")
  },
  {
    name: "interactive-map-components-mobile-actions-mobile-map-action-filters-MobileMapActionFilters",
    path: "/interactive-map/components/mobile-actions/mobile-map-action-filters/MobileMapActionFilters",
    component: () => import("/app/src/pages/interactive-map/components/mobile-actions/mobile-map-action-filters/MobileMapActionFilters.vue")
  },
  {
    name: "interactive-map-components-mobile-actions-mobile-map-action-menu-MobileMapActionMenu",
    path: "/interactive-map/components/mobile-actions/mobile-map-action-menu/MobileMapActionMenu",
    component: () => import("/app/src/pages/interactive-map/components/mobile-actions/mobile-map-action-menu/MobileMapActionMenu.vue")
  },
  {
    name: "interactive-map-components-mobile-actions-mobile-map-action-search-MobileMapActionSearch",
    path: "/interactive-map/components/mobile-actions/mobile-map-action-search/MobileMapActionSearch",
    component: () => import("/app/src/pages/interactive-map/components/mobile-actions/mobile-map-action-search/MobileMapActionSearch.vue")
  },
  {
    name: "interactive-map-components-mobile-actions-MobileMapActions",
    path: "/interactive-map/components/mobile-actions/MobileMapActions",
    component: () => import("/app/src/pages/interactive-map/components/mobile-actions/MobileMapActions.vue")
  },
  {
    name: "interactive-map-components-mobile-burger-menu-MobileMapBurgerMenu",
    path: "/interactive-map/components/mobile-burger-menu/MobileMapBurgerMenu",
    component: () => import("/app/src/pages/interactive-map/components/mobile-burger-menu/MobileMapBurgerMenu.vue")
  },
  {
    name: "interactive-map-components-mobile-favorites-modal-MobileFavoritesModal",
    path: "/interactive-map/components/mobile-favorites-modal/MobileFavoritesModal",
    component: () => import("/app/src/pages/interactive-map/components/mobile-favorites-modal/MobileFavoritesModal.vue")
  },
  {
    name: "interactive-map-components-mobile-map-project-tabs-MobileMapProjectTabs",
    path: "/interactive-map/components/mobile-map-project-tabs/MobileMapProjectTabs",
    component: () => import("/app/src/pages/interactive-map/components/mobile-map-project-tabs/MobileMapProjectTabs.vue")
  },
  {
    name: "interactive-map-components-mobile-onboarding-MobileMapOnboarding",
    path: "/interactive-map/components/mobile-onboarding/MobileMapOnboarding",
    component: () => import("/app/src/pages/interactive-map/components/mobile-onboarding/MobileMapOnboarding.vue")
  },
  {
    name: "interactive-map-components-mobile-search-overlay-MobileMapSearchOverlay",
    path: "/interactive-map/components/mobile-search-overlay/MobileMapSearchOverlay",
    component: () => import("/app/src/pages/interactive-map/components/mobile-search-overlay/MobileMapSearchOverlay.vue")
  },
  {
    name: "interactive-map-components-overlay-router-view-MapModalRouterView",
    path: "/interactive-map/components/overlay-router-view/MapModalRouterView",
    component: () => import("/app/src/pages/interactive-map/components/overlay-router-view/MapModalRouterView.vue")
  },
  {
    name: "interactive-map-components-project-project-hud-components-back-to-project-BackToProject",
    path: "/interactive-map/components/project/project-hud/components/back-to-project/BackToProject",
    component: () => import("/app/src/pages/interactive-map/components/project/project-hud/components/back-to-project/BackToProject.vue")
  },
  {
    name: "interactive-map-components-project-project-hud-components-project-amenities-ProjectAmenities",
    path: "/interactive-map/components/project/project-hud/components/project-amenities/ProjectAmenities",
    component: () => import("/app/src/pages/interactive-map/components/project/project-hud/components/project-amenities/ProjectAmenities.vue")
  },
  {
    name: "interactive-map-components-project-project-hud-components-project-facilities-ProjectFacilities",
    path: "/interactive-map/components/project/project-hud/components/project-facilities/ProjectFacilities",
    component: () => import("/app/src/pages/interactive-map/components/project/project-hud/components/project-facilities/ProjectFacilities.vue")
  },
  {
    name: "interactive-map-components-project-project-hud-composables-useAmenitiesUtils",
    path: "/interactive-map/components/project/project-hud/composables/useAmenitiesUtils",
    component: () => import("/app/src/pages/interactive-map/components/project/project-hud/composables/useAmenitiesUtils.ts")
  },
  {
    name: "interactive-map-components-project-project-hud-composables-useFacilitiesUtils",
    path: "/interactive-map/components/project/project-hud/composables/useFacilitiesUtils",
    component: () => import("/app/src/pages/interactive-map/components/project/project-hud/composables/useFacilitiesUtils.ts")
  },
  {
    name: "interactive-map-components-project-project-hud-composables-useProjectTabs",
    path: "/interactive-map/components/project/project-hud/composables/useProjectTabs",
    component: () => import("/app/src/pages/interactive-map/components/project/project-hud/composables/useProjectTabs.ts")
  },
  {
    name: "interactive-map-components-project-project-hud-MapProjectHud",
    path: "/interactive-map/components/project/project-hud/MapProjectHud",
    component: () => import("/app/src/pages/interactive-map/components/project/project-hud/MapProjectHud.vue")
  },
  {
    name: "interactive-map-components-top-right-items-components-map-login-MapLogin",
    path: "/interactive-map/components/top-right-items/components/map-login/MapLogin",
    component: () => import("/app/src/pages/interactive-map/components/top-right-items/components/map-login/MapLogin.vue")
  },
  {
    name: "interactive-map-components-top-right-items-components-settings-picker-MapSettingsPicker",
    path: "/interactive-map/components/top-right-items/components/settings-picker/MapSettingsPicker",
    component: () => import("/app/src/pages/interactive-map/components/top-right-items/components/settings-picker/MapSettingsPicker.vue")
  },
  {
    name: "interactive-map-components-top-right-items-MapTopRightItems",
    path: "/interactive-map/components/top-right-items/MapTopRightItems",
    component: () => import("/app/src/pages/interactive-map/components/top-right-items/MapTopRightItems.vue")
  },
  {
    name: "interactive-map-composables-use-block-zoom",
    path: "/interactive-map/composables/use-block-zoom",
    component: () => import("/app/src/pages/interactive-map/composables/use-block-zoom/index.ts")
  },
  {
    name: "interactive-map-composables-use-mobile-map-onboarding",
    path: "/interactive-map/composables/use-mobile-map-onboarding",
    component: () => import("/app/src/pages/interactive-map/composables/use-mobile-map-onboarding/index.ts")
  },
  {
    name: "interactive-map-constants-map-config",
    path: "/interactive-map/constants/map-config",
    component: () => import("/app/src/pages/interactive-map/constants/map-config.ts")
  },
  {
    name: "interactive-map-constants-seo",
    path: "/interactive-map/constants/seo",
    component: () => import("/app/src/pages/interactive-map/constants/seo.ts")
  },
  {
    name: "interactive-map-interactive-map.page.mobile",
    path: "/interactive-map/interactive-map.page.mobile",
    component: () => import("/app/src/pages/interactive-map/interactive-map.page.mobile.vue")
  },
  {
    name: "interactive-map-interactive-map.page",
    path: "/interactive-map/interactive-map.page",
    component: () => import("/app/src/pages/interactive-map/interactive-map.page.vue")
  },
  {
    name: "privacy-components-PrivacyContent",
    path: "/privacy/components/PrivacyContent",
    component: () => import("/app/src/pages/privacy/components/PrivacyContent.vue")
  },
  {
    name: "privacy-constants-seo",
    path: "/privacy/constants/seo",
    component: () => import("/app/src/pages/privacy/constants/seo.ts")
  },
  {
    name: "privacy-privacy.page.mobile",
    path: "/privacy/privacy.page.mobile",
    component: () => import("/app/src/pages/privacy/privacy.page.mobile.vue")
  },
  {
    name: "privacy-privacy.page",
    path: "/privacy/privacy.page",
    component: () => import("/app/src/pages/privacy/privacy.page.vue")
  },
  {
    name: "profile-components-avatar-section-desktop-AvatarSection",
    path: "/profile/components/avatar-section/desktop/AvatarSection",
    component: () => import("/app/src/pages/profile/components/avatar-section/desktop/AvatarSection.vue")
  },
  {
    name: "profile-components-avatar-section-mobile-MobileAvatarSection",
    path: "/profile/components/avatar-section/mobile/MobileAvatarSection",
    component: () => import("/app/src/pages/profile/components/avatar-section/mobile/MobileAvatarSection.vue")
  },
  {
    name: "profile-components-favorites-section-composables-use-favorites-list",
    path: "/profile/components/favorites-section/composables/use-favorites-list",
    component: () => import("/app/src/pages/profile/components/favorites-section/composables/use-favorites-list/index.ts")
  },
  {
    name: "profile-components-favorites-section-desktop-FavoritesSection",
    path: "/profile/components/favorites-section/desktop/FavoritesSection",
    component: () => import("/app/src/pages/profile/components/favorites-section/desktop/FavoritesSection.vue")
  },
  {
    name: "profile-components-favorites-section-item-availability-FavoriteAvailabilityItem",
    path: "/profile/components/favorites-section/item/availability/FavoriteAvailabilityItem",
    component: () => import("/app/src/pages/profile/components/favorites-section/item/availability/FavoriteAvailabilityItem.vue")
  },
  {
    name: "profile-components-favorites-section-item-project-FavoriteProjectItem",
    path: "/profile/components/favorites-section/item/project/FavoriteProjectItem",
    component: () => import("/app/src/pages/profile/components/favorites-section/item/project/FavoriteProjectItem.vue")
  },
  {
    name: "profile-components-favorites-section-mobile-MobileFavoritesSection",
    path: "/profile/components/favorites-section/mobile/MobileFavoritesSection",
    component: () => import("/app/src/pages/profile/components/favorites-section/mobile/MobileFavoritesSection.vue")
  },
  {
    name: "profile-constants-seo",
    path: "/profile/constants/seo",
    component: () => import("/app/src/pages/profile/constants/seo.ts")
  },
  {
    name: "profile-profile.page.mobile",
    path: "/profile/profile.page.mobile",
    component: () => import("/app/src/pages/profile/profile.page.mobile.vue")
  },
  {
    name: "profile-profile.page",
    path: "/profile/profile.page",
    component: () => import("/app/src/pages/profile/profile.page.vue")
  },
  {
    name: "projects-components-mobile-exclusive-projects-list-MobileExclusiveProjectsList",
    path: "/projects/components/mobile-exclusive-projects-list/MobileExclusiveProjectsList",
    component: () => import("/app/src/pages/projects/components/mobile-exclusive-projects-list/MobileExclusiveProjectsList.vue")
  },
  {
    name: "projects-components-mobile-project-skeleton-MobileProjectSkeleton",
    path: "/projects/components/mobile-project-skeleton/MobileProjectSkeleton",
    component: () => import("/app/src/pages/projects/components/mobile-project-skeleton/MobileProjectSkeleton.vue")
  },
  {
    name: "projects-components-mobile-projects-list-MobileProjectsList",
    path: "/projects/components/mobile-projects-list/MobileProjectsList",
    component: () => import("/app/src/pages/projects/components/mobile-projects-list/MobileProjectsList.vue")
  },
  {
    name: "projects-components-mobile-projects-section-MobileProjectsSection",
    path: "/projects/components/mobile-projects-section/MobileProjectsSection",
    component: () => import("/app/src/pages/projects/components/mobile-projects-section/MobileProjectsSection.vue")
  },
  {
    name: "projects-projects.page.mobile",
    path: "/projects/projects.page.mobile",
    component: () => import("/app/src/pages/projects/projects.page.mobile.vue")
  },
  {
    name: "projects-projects.page",
    path: "/projects/projects.page",
    component: () => import("/app/src/pages/projects/projects.page.vue")
  },
  {
    name: "similar-properties-similar-properties.page.mobile",
    path: "/similar-properties/similar-properties.page.mobile",
    component: () => import("/app/src/pages/similar-properties/similar-properties.page.mobile.vue")
  },
  {
    name: "single-availability-components-availability-navbar-AvailabilityNavbar",
    path: "/single-availability/components/availability-navbar/AvailabilityNavbar",
    component: () => import("/app/src/pages/single-availability/components/availability-navbar/AvailabilityNavbar.vue")
  },
  {
    name: "single-availability-components-availability-plug-AvailabilityPlug",
    path: "/single-availability/components/availability-plug/AvailabilityPlug",
    component: () => import("/app/src/pages/single-availability/components/availability-plug/AvailabilityPlug.vue")
  },
  {
    name: "single-availability-components-gallery-section-GallerySection",
    path: "/single-availability/components/gallery-section/GallerySection",
    component: () => import("/app/src/pages/single-availability/components/gallery-section/GallerySection.vue")
  },
  {
    name: "single-availability-components-main-section-components-availability-3d-floorplan-Availability3DFloorPlan",
    path: "/single-availability/components/main-section/components/availability-3d-floorplan/Availability3DFloorPlan",
    component: () => createClientPage(() => import("/app/src/pages/single-availability/components/main-section/components/availability-3d-floorplan/Availability3DFloorPlan.client.vue"))
  },
  {
    name: "single-availability-components-main-section-components-availabilty-details-AvailabilityDetails",
    path: "/single-availability/components/main-section/components/availabilty-details/AvailabilityDetails",
    component: () => import("/app/src/pages/single-availability/components/main-section/components/availabilty-details/AvailabilityDetails.vue")
  },
  {
    name: "single-availability-components-main-section-MainSection",
    path: "/single-availability/components/main-section/MainSection",
    component: () => import("/app/src/pages/single-availability/components/main-section/MainSection.vue")
  },
  {
    name: "single-availability-components-mobile-details-section-components-mobile-floorplan-details-MobileFloorplanDetails",
    path: "/single-availability/components/mobile/details-section/components/mobile-floorplan-details/MobileFloorplanDetails",
    component: () => import("/app/src/pages/single-availability/components/mobile/details-section/components/mobile-floorplan-details/MobileFloorplanDetails.vue")
  },
  {
    name: "single-availability-components-mobile-details-section-MobileAvailabilityDetailsSection",
    path: "/single-availability/components/mobile/details-section/MobileAvailabilityDetailsSection",
    component: () => import("/app/src/pages/single-availability/components/mobile/details-section/MobileAvailabilityDetailsSection.vue")
  },
  {
    name: "single-availability-components-mobile-main-section-components-mobile-availability-floorplan-overlay-MobileAvailabilityFloorplanOverlay",
    path: "/single-availability/components/mobile/main-section/components/mobile-availability-floorplan-overlay/MobileAvailabilityFloorplanOverlay",
    component: () => import("/app/src/pages/single-availability/components/mobile/main-section/components/mobile-availability-floorplan-overlay/MobileAvailabilityFloorplanOverlay.vue")
  },
  {
    name: "single-availability-components-mobile-main-section-MobileAvailabilityMainSection",
    path: "/single-availability/components/mobile/main-section/MobileAvailabilityMainSection",
    component: () => import("/app/src/pages/single-availability/components/mobile/main-section/MobileAvailabilityMainSection.vue")
  },
  {
    name: "single-availability-components-mobile-similar-properties-section-MobileSimilarPropertiesSection",
    path: "/single-availability/components/mobile/similar-properties-section/MobileSimilarPropertiesSection",
    component: () => import("/app/src/pages/single-availability/components/mobile/similar-properties-section/MobileSimilarPropertiesSection.vue")
  },
  {
    name: "single-availability-components-regulatory-section-RegulatorySection",
    path: "/single-availability/components/regulatory-section/RegulatorySection",
    component: () => import("/app/src/pages/single-availability/components/regulatory-section/RegulatorySection.vue")
  },
  {
    name: "single-availability-components-similar-properties-section-SimilarPropertiesSection",
    path: "/single-availability/components/similar-properties-section/SimilarPropertiesSection",
    component: () => import("/app/src/pages/single-availability/components/similar-properties-section/SimilarPropertiesSection.vue")
  },
  {
    name: "single-availability-components-single-availability-skeleton-mobile-SingleAvailabilitySkeletonMobile",
    path: "/single-availability/components/single-availability-skeleton-mobile/SingleAvailabilitySkeletonMobile",
    component: () => import("/app/src/pages/single-availability/components/single-availability-skeleton-mobile/SingleAvailabilitySkeletonMobile.vue")
  },
  {
    name: "single-availability-components-single-availability-skeleton-SingleAvailabilitySkeleton",
    path: "/single-availability/components/single-availability-skeleton/SingleAvailabilitySkeleton",
    component: () => import("/app/src/pages/single-availability/components/single-availability-skeleton/SingleAvailabilitySkeleton.vue")
  },
  {
    name: "single-availability-composables-use-head-meta",
    path: "/single-availability/composables/use-head-meta",
    component: () => import("/app/src/pages/single-availability/composables/use-head-meta.ts")
  },
  {
    name: "single-availability-composables-use-single-availability",
    path: "/single-availability/composables/use-single-availability",
    component: () => import("/app/src/pages/single-availability/composables/use-single-availability.ts")
  },
  {
    name: "single-availability-single-availability.page.mobile",
    path: "/single-availability/single-availability.page.mobile",
    component: () => import("/app/src/pages/single-availability/single-availability.page.mobile.vue")
  },
  {
    name: "single-availability-single-availability.page",
    path: "/single-availability/single-availability.page",
    component: () => import("/app/src/pages/single-availability/single-availability.page.vue")
  },
  {
    name: "single-project-components-availabilities-section-AvailabilitiesSection",
    path: "/single-project/components/availabilities-section/AvailabilitiesSection",
    component: () => import("/app/src/pages/single-project/components/availabilities-section/AvailabilitiesSection.vue")
  },
  {
    name: "single-project-components-availabilities-section-components-filters-ProjectAvailabilitiesSectionFilters",
    path: "/single-project/components/availabilities-section/components/filters/ProjectAvailabilitiesSectionFilters",
    component: () => import("/app/src/pages/single-project/components/availabilities-section/components/filters/ProjectAvailabilitiesSectionFilters.vue")
  },
  {
    name: "single-project-components-download-brochure-button-DownloadBrochureButton",
    path: "/single-project/components/download-brochure-button/DownloadBrochureButton",
    component: () => import("/app/src/pages/single-project/components/download-brochure-button/DownloadBrochureButton.vue")
  },
  {
    name: "single-project-components-main-section-MainSection",
    path: "/single-project/components/main-section/MainSection",
    component: () => import("/app/src/pages/single-project/components/main-section/MainSection.vue")
  },
  {
    name: "single-project-components-mobile-availabilities-section-components-mobile-availability-list-MobileAvailabilityList",
    path: "/single-project/components/mobile/availabilities-section/components/mobile-availability-list/MobileAvailabilityList",
    component: () => import("/app/src/pages/single-project/components/mobile/availabilities-section/components/mobile-availability-list/MobileAvailabilityList.vue")
  },
  {
    name: "single-project-components-mobile-availabilities-section-components-mobile-floorplan-list-MobileFloorplanList",
    path: "/single-project/components/mobile/availabilities-section/components/mobile-floorplan-list/MobileFloorplanList",
    component: () => import("/app/src/pages/single-project/components/mobile/availabilities-section/components/mobile-floorplan-list/MobileFloorplanList.vue")
  },
  {
    name: "single-project-components-mobile-availabilities-section-MobileAvailabilitiesSection",
    path: "/single-project/components/mobile/availabilities-section/MobileAvailabilitiesSection",
    component: () => import("/app/src/pages/single-project/components/mobile/availabilities-section/MobileAvailabilitiesSection.vue")
  },
  {
    name: "single-project-components-mobile-project-main-section-MobileProjectMainSection",
    path: "/single-project/components/mobile/project-main-section/MobileProjectMainSection",
    component: () => import("/app/src/pages/single-project/components/mobile/project-main-section/MobileProjectMainSection.vue")
  },
  {
    name: "single-project-components-project-navbar-ProjectNavbar",
    path: "/single-project/components/project-navbar/ProjectNavbar",
    component: () => import("/app/src/pages/single-project/components/project-navbar/ProjectNavbar.vue")
  },
  {
    name: "single-project-components-single-project-skeleton-mobile-SingleProjectSkeletonMobile",
    path: "/single-project/components/single-project-skeleton-mobile/SingleProjectSkeletonMobile",
    component: () => import("/app/src/pages/single-project/components/single-project-skeleton-mobile/SingleProjectSkeletonMobile.vue")
  },
  {
    name: "single-project-components-single-project-skeleton-SingleProjectSkeleton",
    path: "/single-project/components/single-project-skeleton/SingleProjectSkeleton",
    component: () => import("/app/src/pages/single-project/components/single-project-skeleton/SingleProjectSkeleton.vue")
  },
  {
    name: "single-project-composables",
    path: "/single-project/composables",
    component: () => import("/app/src/pages/single-project/composables/index.ts")
  },
  {
    name: "single-project-composables-use-page-head",
    path: "/single-project/composables/use-page-head",
    component: () => import("/app/src/pages/single-project/composables/use-page-head.ts")
  },
  {
    name: "single-project-composables-use-single-project",
    path: "/single-project/composables/use-single-project",
    component: () => import("/app/src/pages/single-project/composables/use-single-project.ts")
  },
  {
    name: "single-project-single-project.page.mobile",
    path: "/single-project/single-project.page.mobile",
    component: () => import("/app/src/pages/single-project/single-project.page.mobile.vue")
  },
  {
    name: "single-project-single-project.page",
    path: "/single-project/single-project.page",
    component: () => import("/app/src/pages/single-project/single-project.page.vue")
  },
  {
    name: "terms-components-TermsContent",
    path: "/terms/components/TermsContent",
    component: () => import("/app/src/pages/terms/components/TermsContent.vue")
  },
  {
    name: "terms-constants-seo",
    path: "/terms/constants/seo",
    component: () => import("/app/src/pages/terms/constants/seo.ts")
  },
  {
    name: "terms-terms.page.mobile",
    path: "/terms/terms.page.mobile",
    component: () => import("/app/src/pages/terms/terms.page.mobile.vue")
  },
  {
    name: "terms-terms.page",
    path: "/terms/terms.page",
    component: () => import("/app/src/pages/terms/terms.page.vue")
  },
  {
    name: "user-data-components-user-data-content",
    path: "/user-data/components/user-data-content",
    component: () => import("/app/src/pages/user-data/components/user-data-content.vue")
  },
  {
    name: "user-data-constants-seo",
    path: "/user-data/constants/seo",
    component: () => import("/app/src/pages/user-data/constants/seo.ts")
  },
  {
    name: "user-data-user-data.page.mobile",
    path: "/user-data/user-data.page.mobile",
    component: () => import("/app/src/pages/user-data/user-data.page.mobile.vue")
  },
  {
    name: "user-data-user-data.page",
    path: "/user-data/user-data.page",
    component: () => import("/app/src/pages/user-data/user-data.page.vue")
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/buy",
    component: component_45stubnO9MU04yTU
  }
]